
path {
    fill: transparent;
}

text {
    fill: #FBD81A;
    text-shadow: -1px 1px 1px black,
                 -3px 2px 1px black,
                 -4px 3px 1px black;
    font-weight: 700;
    font-family: 'AntiqueOlive-Regular';
    letter-spacing: .075rem;
}
