@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'AntiqueOlive-Regular';
    src: url('./fonts/AntiqueOlive-Regular-webfont.woff2') format('woff2'),
         url('./fonts/AntiqueOlive-Regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'myriadpro-regular';
    src: url('./fonts/myriadpro-regular-webfont.woff2') format('woff2'),
         url('./fonts/myriadpro-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'grilled-cheese-btn-regular';
    src: url('./fonts/grilled-cheese-btn-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


body {
  margin: 0;
  /* font-family: 'myriadpro-regular'; */
  font-family: 'AntiqueOlive-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.grilled-cheese {
    font-family: 'grilled-cheese-btn-regular';
}

.antique-olive {
    font-family: 'AntiqueOlive-Regular';
}


.font-lucky {
    font-family: "Luckiest Guy", "Comic Sans", "Arial Black", Arial, Sans Serif;
}

.badge {
    background-color: #ED1A25;
    padding: 4px 8px;
    text-align: center;
    border-radius: 5px;
    text-shadow: 2px 3px 2px rgba(22,22,22, .5);
    box-shadow: 2px 3px 2px rgba(22,22,22, .6);
}

.text-shadow {
    text-shadow: 2px 2px 2px rgba(11,11,11, .8);
}
.text-shadow-thin {
    text-shadow: 1px 1px 1px rgba(11,11,11, .8);
}
.font-comic {
    font-family: 'Comic Sans MS', 'Chalkboard SE', 'Comic Neue', sans-serif;
}

.soft-outline {
    text-shadow: 0 0 4px black, 0 0 4px black, 0 0 4px black, 0 0 4px black, 0 0 4px black, 0 0 4px black, 0 0 4px black, 0 0 4px black, 0 0 4px black, 0 0 4px black, 0 0 4px black, 0 0 4px black, 0 0 4px black, 0 0 4px black
}
.modal {
    transition: opacity 0.25s ease;
}

.modal-active {
    overflow: hidden;
}
video.fullscreen {
    position: absolute;
    z-index: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.rookie-shadow {
    text-shadow:0px 0px 2px rgba(255,255,255,1.00),
                1px 1px 2px rgba(255,255,255,1.00),
                -1px 1px 2px rgba(255,255,255,1.00),
                -2px 2px 2px rgba(255,255,255,1.00),
                -1px 1px rgba(0,0,0, 1.00),
                -2px 2px rgba(0,0,0, 1.00),
                -3px 3px rgba(0,0,0, 1.00),
                -4px 4px rgba(0,0,0, 1.00),
                -5px 5px rgba(0,0,0, 1.00),
                -6px 6px rgba(0,0,0, 1.00),
                -7px 7px rgba(0,0,0, 1.00),
                -8px 8px rgba(0,0,0, 1.00),
                -9px 9px rgba(0,0,0, 1.00),
                -10px 10px rgba(0,0,0, 1.00),
                -11px 11px rgba(0,0,0, 1.00),
                -12px 12px rgba(0,0,0, 1.00);
                /* -13px 13px rgba(0,0,0, 1.00),
                -14px 14px rgba(0,0,0, 1.00),
                -15px 15px rgba(0,0,0, 1.00),
                -16px 16px rgba(0,0,0, 1.00),
                -17px 17px rgba(0,0,0, 1.00),
                -18px 18px rgba(0,0,0, 1.00),
                -19px 19px rgba(0,0,0, 1.00); */
}

.audience {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    max-width: 90%;
}

.read {
    margin-bottom: 5rem;
}
.read a {
    background-color: #fff;
    padding: 0.8em 1.6em;
    color: black;
    text-decoration: none;
    border-radius: 1em;
    box-shadow: -4px 4px 2px #000000;
    z-index: 999;
}
