.large-bullets li:before {
    content:"·";
    font-size:120px;
    position: relative;
    top: 24px;
    line-height: 16px;
}

#openModalBtn {
    border: 4px solid white;
    box-shadow: 0 3px 3px 3px rgba(2,2,2, .2);
}


.text-pill {
    border-radius: 16rem;
}

.pill-outline {
    border: 5px solid black;
}

.ReactModal__Content--after-open {
    overflow-y: scroll !important;
    max-height: 90%;
  }