
.nav {
    font-size: 1.8rem;
    margin: 3rem;
}
.nav a {
    text-decoration: none;
    background-color: #0f0f0f;
    color: white;
    padding: 10px 20px;
    border-radius: 0.7em;
}

.nav a {
    background-color: #ed1b24;
    color: white;
    border-color: white;
    border: 3px solid white;
    box-shadow: -5px 5px 0 #000000c4;
}

.nav a:hover {
    background-color: #ed1b24;
    color: yellow;
    box-shadow: -2px 2px 0 #000000ee;
}
