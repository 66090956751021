
.footer {
    font-size: 1.1rem;
    background-color: #000000;
    margin-bottom: 0;
    padding: 10px;
    color: white;
}

.footer a {
    color: white;
    text-decoration: none;
}

.footer a:hover {
    color: #fff200;
}
